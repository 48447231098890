// types

type Props = {
  href: string;
};

// component

export function PreloadFont(props: Props) {
  const {href} = props;
  const ext = href.split('.').pop();

  return (
    <link
      as="font"
      crossOrigin="anonymous"
      href={href}
      rel="preload"
      type={`font/${ext}`}
    />
  );
}
