// types

type Props = {
  href: string;
};

// component

export function PreloadUrl(props: Props) {
  const {href} = props;

  return (
    <link
      href={href}
      rel="prefetch"
    />
  );
}
