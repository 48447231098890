/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-return */

// import

import type {Context, FC, PropsWithChildren, ReactElement} from 'react';

import {useContext, createContext} from 'react';

// types

type Selector<Value> = (value: Value) => any;

type SelectorHooks<Selectors> = {
  [K in keyof Selectors]: () => Selectors[K] extends (...args: any) => infer R
    ? R
    : never;
};

type Hooks<
  Value,
  Selectors extends Array<Selector<Value>>,
> = Selectors['length'] extends 0 ? [() => Value] : SelectorHooks<Selectors>;

type ConstateTuple<Props, Value, Selectors extends Array<Selector<Value>>> = [
  FC<PropsWithChildren<Props>>,
  ...Hooks<Value, Selectors>,
];

// vars

const isDev = process.env.NODE_ENV !== 'production';

const NO_PROVIDER = {};

// fns

function createUseContext(context: Context<any>): any {
  return () => {
    const value = useContext(context);

    if (isDev && value === NO_PROVIDER) {
      const warnMessage = context.displayName ?
        `The context consumer of ${context.displayName} must be wrapped with its corresponding Provider` :
        'Component must be wrapped with Provider.';

      console.warn(warnMessage);
    }

    return value;
  };
}

/// export

export function constate<Props, Value, Selectors extends Array<Selector<Value>>>(
  useValue: (props: Props) => Value,
  ...selectors: Selectors
): ConstateTuple<Props, Value, Selectors> {
  const contexts = [] as Array<Context<any>>;
  const hooks = ([] as unknown) as Hooks<Value, Selectors>;

  const makeContext = (displayName: string) => {
    const context = createContext(NO_PROVIDER);

    if (isDev && displayName) {
      context.displayName = displayName;
    }

    contexts.push(context);
    hooks.push(createUseContext(context));
  };

  if (selectors.length) {
    selectors.forEach((selector) => makeContext(selector.name));
  } else {
    makeContext(useValue.name);
  }

  const Provider: FC<PropsWithChildren<Props>> = ({
    children,
    ...props
  }) => {
    const value = useValue(props as Props);
    let element = children as ReactElement;

    for (let i = 0; i < contexts.length; i += 1) {
      const Ctx = contexts[i] as Context<any>;
      const selector = selectors[i] || ((v) => v);
      element = (
        <Ctx.Provider value={selector(value)}>
          {element}
        </Ctx.Provider>
      );
    }

    return element;
  };

  if (isDev && useValue.name) {
    Provider.displayName = 'Constate';
  }

  return [Provider, ...hooks];
}
