// import

import type {ComponentProps, ReactNode, RefObject} from 'react';

import * as T from '@radix-ui/react-toast';
import {useCallback, useEffect, useRef, useState} from 'react';

import {cram, isStr, uniqueBy} from '@cat/rambo';

import {IconClose} from './Styles/Icons';
import {constate} from './constate';

// types

type Color = 'red' | 'orange' | 'green' | 'blue' | 'pink';

export type ToastProviderProps = ComponentProps<typeof T.ToastProvider> & {
  toasts?: ToastProps[];
};

export type ToastProps = ComponentProps<typeof T.Root> & {
  id?: string;
  closeable?: boolean;

  title: ReactNode;
  message: ReactNode;
  action?: ReactNode;
  actionAlt?: string;
  color?: Color;
};

type ToastIn = ToastProps & {id: string};

// styles

const colorMap: Record<Color, string> = {
  red: cram('text-red-550'),
  orange: cram('text-orange-550'),
  green: cram('text-green-550'),
  blue: cram('text-blue-550'),
  pink: cram('text-pinkZ-550'),
};

const viewportCls = cram(
  'fixed top-0 right-0 z-[1000] p-1r4 w-b4 max-w-[100vw]',
  'flex flex-col-reverse gap-y-r6',
  'list-none outline-none',
);

const toastCls = cram(
  'py-r4 px-r6 rounded-md border-2 border-pinkZ-800',
  'shadow-hard-2 shadow-pinkZ-800',
  'bg-orange-25 flex gap-x-1r gap-y-r4 items-center',
  'max-b3:flex-col max-b3:items-start',
);

const titleCls = cram('font-semibold text-r7 leading-1r4 flex-grow');
const messageCls = cram('prose prose-sm leading-1r2');

// context

const ToastsListContext = () => {
  const [list, setList] = useState<ToastIn[]>([]);

  const postToast = useCallback((toast: ToastProps) => {
    return setList((arr) => {
      const defId = toast.message?.toString() ?? Math.random().toString();

      return uniqueBy(
        [...arr, {id: defId, ...toast}],
        (t) => t.id,
      );
    });
  }, []);

  const closeToast = useCallback((toast: {id: string}) => {
    return setList((arr) => {
      return arr.filter(({id}) => id !== toast.id);
    });
  }, []);

  return [list, postToast, closeToast] as const;
};

const [
  ToastListProvider,
  useToastList,
  usePostToast,
  useCloseToast,
] = constate(
  ToastsListContext,
  (res) => res,
  (res) => res[1],
  (res) => res[2],
);

// hooks

export {usePostToast};

// components

function ToastList(props: {toasts?: ToastProps[]}) {
  const {toasts} = props;
  const [list, postToast] = useToastList();
  const refs = useRef(new WeakMap<{id: string}, RefObject<HTMLDivElement>>());

  useEffect(() => {
    toasts?.forEach((toast) => {
      postToast(toast);
    });
  }, [toasts]);

  return (
    <T.Viewport className={viewportCls}>
      {list.map((toast) => {
        const map = refs.current;
        const id = {id: toast.id};

        if (!map.has(id)) {
          map.set(id, {current: null});
        }

        const nodeRef = map.get(id);

        return nodeRef ? (
          <Toast {...toast} key={toast.id} />
        ) : null;
      })}
    </T.Viewport>
  );
}

export function ToastProvider(props: ToastProviderProps) {
  const {children, toasts, ...rest} = props;

  return (
    <ToastListProvider>
      <T.ToastProvider {...rest}>
        {children}

        <ToastList toasts={toasts} />
      </T.ToastProvider>
    </ToastListProvider>
  );
}

export function Toast(props: ToastProps) {
  // eslint-disable-next-line @typescript-eslint/unbound-method
  const {title, message, closeable, action, actionAlt, className, onOpenChange: onChange, id, color, ...rest} = props;
  const closeToast = useCloseToast();

  const onOpenChange = (open: boolean) => {
    onChange?.(open);

    if (!open && id) {
      closeToast({id});
    }
  };

  return (
    <T.Root
      {...rest}
      className={cram(toastCls, className)}
      onOpenChange={onOpenChange}
    >
      <div className="flex-grow">
        <div className="flex gap-r6">
          <T.Title className={cram(titleCls, color && colorMap[color])}>
            {isStr(title) ? (
              <div dangerouslySetInnerHTML={{__html: title}} />
            ) : title}
          </T.Title>

          {!action && closeable ? (
            <T.Close asChild className="flex-grow-0 cursor-pointer">
              <IconClose size={16} />
            </T.Close>
          ) : null}
        </div>

        <T.Description className={messageCls}>
          {isStr(message) ? (
            <div dangerouslySetInnerHTML={{__html: message}} />
          ) : message}
        </T.Description>
      </div>

      {action ? (
        <T.Action asChild altText={actionAlt as string} className="flex-grow-0">
          {isStr(action) ? (
            <div dangerouslySetInnerHTML={{__html: action}} />
          ) : action}
        </T.Action>
      ) : null}
    </T.Root>
  );
}
