// import

import type {ToastProps} from './modules/Toast';
import type {LoaderFunctionArgs} from '@remix-run/node';
import type {ErrorBoundaryProps} from '@sentry/remix';
import type {PropsWithChildren} from 'react';

import {ClerkApp as withClerk, ClerkErrorBoundary as withClerkError} from '@clerk/remix';
import {rootAuthLoader} from '@clerk/remix/ssr.server';
import sans400 from '@fontsource/fira-sans-condensed/files/fira-sans-condensed-latin-400-normal.woff2';
import sans600 from '@fontsource/fira-sans-condensed/files/fira-sans-condensed-latin-600-normal.woff2';
import sans700 from '@fontsource/fira-sans-condensed/files/fira-sans-condensed-latin-700-normal.woff2';
import inter from '@fontsource-variable/inter/files/inter-latin-standard-normal.woff2';
import {json} from '@remix-run/node';
import {Links, Meta, Outlet, Scripts, ScrollRestoration, useLoaderData, useRouteError} from '@remix-run/react';
import {withSentry, captureRemixErrorBoundaryError} from '@sentry/remix';
import {useEffect} from 'react';

import {toastSession} from '~/libs/cookies';
import {env, siteName} from '~/libs/env';
import {PreloadFont} from '~/modules/Html/PreloadFont';
import {PreloadUrl} from '~/modules/Html/PreloadUrl';

import {processEnv, stripePublicKey} from './libs/env.server';
import {RootContext} from './modules/App/RootContext';
import {ErrorView} from './modules/ErrorView';
import './styles.css';

import '@fontsource/fira-sans-condensed/latin-ext.css';
import '@fontsource/fira-sans-condensed/latin-italic.css';
import '@fontsource/fira-sans-condensed/latin.css';
import '@fontsource-variable/fira-code/index.css';
import '@fontsource-variable/inter/standard.css';

// types

declare global {
  interface Window {
    stripeEnv: {publicKey: string};
  }
}

// config

export const loader = (args: LoaderFunctionArgs) => {
  return rootAuthLoader(args, async ({request}) => {
    const cookie = request.headers.get('Cookie');
    const toast = await toastSession.getSession(cookie);

    return json({
      windowEnv: processEnv,
      stripeEnv: {publicKey: stripePublicKey},
      toasts: toast.get('messages'),
    }, {
      headers: {
        'Set-Cookie': await toastSession.commitSession(toast),
      },
    });
  });
};

// components

export default withSentry(withClerk(() => {
  const {windowEnv, stripeEnv, toasts} = useLoaderData<typeof loader>();

  return (
    <Document>
      <RootContext toasts={toasts as ToastProps[]}>
        <Outlet />
      </RootContext>

      <script
        dangerouslySetInnerHTML={{
          __html: `
            window.env = ${JSON.stringify(windowEnv)}; 
            window.stripeEnv = ${JSON.stringify(stripeEnv)};
          `,
        }}
      />
    </Document>
  );
}));

export const ErrorBoundary = withClerkError((props: ErrorBoundaryProps) => {
  const err = useRouteError();

  const status = (err as any).status as number ?? '';

  useEffect(() => {
    captureRemixErrorBoundaryError(err);
  }, []);

  return (
    <Document>
      <ErrorView title={[status, 'Server Error!'].join(' ').trim()}>
        <div className="prose">
          <p>Something went wrong, but not to worry! The Technical Support Cats have been notified.</p>

          <p>If you have any context that might help solve the problem, please contact us. Otherwise, try again soon.</p>
        </div>
      </ErrorView>

      <script dangerouslySetInnerHTML={{__html: `window.env = {};`}} />
    </Document>
  );
});

function Document(props: PropsWithChildren) {
  const {children} = props;

  // Bake-in default meta to avoid merging in nested routes...
  return (
    <html lang="en">
      <head>
        {/* meta */}
        <meta charSet="utf-8" />
        <meta content="width=device-width, initial-scale=1, minimum-scale=1, maximum-scale=3" name="viewport" />

        {/* icons */}
        <link href="/icon/favicon.ico" rel="icon" sizes="any" />
        <link href="/icon/favicon.svg" rel="icon" type="image/svg+xml" />
        <link href="/icon/apple-touch-icon.png" rel="apple-touch-icon" />
        <link href="/icon/manifest.json" rel="manifest" />
        <meta content={siteName} name="apple-mobile-web-app-title" />
        <meta content="#FCE7CF" name="theme-color" />

        {/* optimize */}
        <PreloadUrl href={env.wwwUrl} />
        <PreloadFont href={inter} />
        <PreloadFont href={sans400} />
        <PreloadFont href={sans600} />
        <PreloadFont href={sans700} />

        {/* seo */}
        <link href="/sitemap.xml" rel="sitemap" />

        {/* analytics */}
        <script
          defer
          data-api="/scry/api/event"
          data-domain={new URL(env.appUrl).hostname}
          src="/scry/js/script.outbound-links.file-downloads.tagged-events.revenue.pageview-props.exclusions.js"
        />
        <script
          dangerouslySetInnerHTML={{
            __html: `
              window.plausible = window.plausible || 
              function plausible(...args) {
                (window.plausible.q = window.plausible.q || []).push(args);
              };
            `,
          }}
        />

        {/* dynamic */}
        <Meta />
        <Links />
      </head>

      <body className="dots bg-orange-100 text-pinkZ-800 font-body text-1r2">
        {children}

        <ScrollRestoration />
        <Scripts />
      </body>
    </html>
  );
}
