// import

import type {ToastProps} from '../Toast';
import type {PropsWithChildren} from 'react';

import {ToastProvider} from '../Toast';
import {TooltipProvider} from '../Tooltip';

// types

type Props = PropsWithChildren<{
  toasts?: ToastProps[];
}>;

// component

export function RootContext(props: Props) {
  const {children, toasts} = props;

  return (
    <ToastProvider toasts={toasts}>
      <TooltipProvider>
        {children}
      </TooltipProvider>
    </ToastProvider>
  );
}
